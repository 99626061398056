// $site-logo-size: $site-tagline-height + $main-nav-height + $sub-nav-height + 3;
// $site-logo-size-fixed: $site-tagline-height-fixed + $main-nav-height-fixed + $sub-nav-height-fixed + 3;


#sn-site-logo {
  position: absolute;
  top: $main-nav-height / 2;
  transform: translateY(-50%);
  // border-bottom: 10px solid transparent;
  left: 0;
  width: $site-logo-width;
  height: $site-logo-height;
  // padding: 8px;

  .sn-site-logo-wrap {
    display: table;
    table-layout: fixed;
    height: 100%;
    width: 100%;
  }

  .sn-site-logo-background {
    display: table-cell;
    vertical-align: middle;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}
