#siteContainer {
  &, #siteContainer.container-fluid {
    padding: $page-element-margin 0;
    width: 100%;
    max-width: $maxWidth;
    min-height: 100%;
    min-height: 100vh;
    min-height: calc(100vh - #{$nav-placeholder-height} - #{$site-footer-height} - #{$account-nav-height-desktop});
    margin: 0 auto 40px auto;
    background: #fff;
    .home.user_mode:not(.newsPage) &{
      background: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
    // .newsPage{
    //   background: #fff !important;
    // }
  }

  @media only screen and (max-width: 1024px) { max-width:1024px; }

  #displayBodyHeader:not(.displayBodyHeader-image) {
    padding: $page-element-margin 0 ($page-element-margin * 2) 0;
    font-size: 42px;
    font-weight: 600;
    border-bottom: 1px solid $gray;
    color: $secondary-color;
    text-transform: uppercase;
    margin: 0 ($page-element-margin * 2) $page-element-margin ($page-element-margin * 2);
  }

  #yieldContent.row { margin: auto; }

  #panelOne { padding: 0; }

  .yieldPageContent { padding:0; }
}

.edit_mode #panelTwo{
  padding: 0 ($page-element-margin * 2);
}
