.site-presenter{
  max-width: 293px;
  width: 100%;
  display: flex;
  flex-flow: column-reverse;
  // background-color: $secondary-color;
  margin: 0 auto 70px auto;
  min-height: 46px;
  justify-content: center;
  position: relative;
  .presenter-prefix{
    font-size: 10px;
    font-family: $fontOne;
    text-transform: none;
    text-align: center;
    margin: 2px 0;
    color: $primary-color;
  }
  a{
    text-align: center;
  }
  img{
    max-width: 124px;
    position: relative;
    left: 29%;
  }
  br{display: none;}
  &:before{
    z-index: -1;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    border-top: 46px solid $secondary-color;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 0px solid transparent;
    height: 0;
    max-width: 253px;
    width: 100%;
    margin: 0 auto;
  }
}
