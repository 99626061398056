.sponsor-textblock{
  .text{
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    p{
      margin: 10px;
    }
    img{
      max-width: 150px;
      width: 100%;
    }
  }
}
