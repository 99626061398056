/* Content Tabbed Navigation
-------------------------------------------------------------------- */
@mixin tabs(){
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: stretch;
  // border-bottom: 4px solid $primary-color;
  // box-shadow: inset 1px 1px 0px 0px $gray;
  li{
    background-color: $secondary-color;
    display: block;
    flex: 1 0 auto;
    border: 1px solid transparent;
    background-clip: padding-box;
    // box-shadow: inset -1px -1px 0px 0px $gray;
    transition: background-color $transition-linear;
    span{
      display: block;
      a{
        font-size: 18px;
        font-weight: 400;
        display: block;
        padding: 8px;
        width: 100%;
        text-align: center;
        color: #FFF;
        transition: color $transition-linear;
        &:hover{
          text-decoration: none;
        }
      }
    }
    &:hover,
    &.selected{
      background-color: $primary-color;
      // box-shadow: inset -1px -1px 0px 0px $primary-color;
      a{
        color: $white;
      }
    }
  }
}


.contentTabs {
  @include tabs;
  // display: block;
  list-style-type: none;
  // text-align: left;
  width: 100%;
  // margin: 10px 0;

  &:after { clear: both; }

  &:before,
  &:after {
    content: " ";
    display: table;
  }

  li {
    // @include content-tab-item;

    span {
      a {
        // @include content-tab;
        // span { display: inline; }
      }
    }

    &:hover,
    &.selected {
      // background-color: $primary-color;
      a{
        // color: $white;
      }
    }
  }

  &.rapidTabsConstruction a.tabLink {
    padding-top: 4px;
    padding-left: 8px;
    display: inline-block;
    background: none;
  }
}

.pageElementAdmin .rosterPlayerStatElement .contentTabs { margin: 0; }

.dataTable .disabled {
  color: #000;
  text-decoration: none;

  &:hover { text-decoration: underline; }
}


/* Loading Tab Animation
-------------------------------------------------------------------- */
#contentTabsLoader {
  text-align: center;
  clear: both;
  padding: 25px;
}


/* Rapid Tabs Construction
-------------------------------------------------------------------- */
.rapidTabsConstruction {
  $construction-yellow: #fccd22;
  $construction-yellow-hover: darken($construction-yellow, 10%);
  background: #FDDD40;
  border-bottom: none;
  box-shadow: inset 1px 1px 0px 0px $construction-yellow-hover;

  li {
    box-shadow: inset -1px -1px 0px 0px $construction-yellow-hover;
    border: 0;
    background-color: transparent;
    background-clip: initial;
    span a {
      color: #333;
      font-size: 14px; }
    &:hover,
    &.selected{
      background-color: #fccd22;
      box-shadow: inset -1px -1px 0px 0px $construction-yellow-hover;
      span a{
        // background-color: #FDE872;
        color: #333;
      }
    }
  }
}
