// 1. From Theme
// 2. From Layout Adjustments

// 1. From Theme
// Other background styles are rendered inline (based on user settings)
.site-background {
  position: absolute;
}


// 2. From Layout Adjustments
html {
  &:not(.has-sub-nav) {
    .site-background { margin-top: -$sub-nav-height; }
  }
  &.collapsed-mobile-nav {
    .site-background { margin-top: -$nav-placeholder-height; }
  }
}
.site-background{
  top: 130px !important;
  .page-manager-visible &{
    background-position: calc(50% + #{$page-manager-width/2}) top;
  }
}


// .gray-background{
//   float: left;
//   width: 100%;
// }
// .gray-background + .gray-background:last-of-type{
//   background-color: #a2a9af;
//   background-image: url(../images/background-blue-sm.jpg);
//   background-size: cover;
//   background-position: center;
//   float: none;
// }
